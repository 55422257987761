/* TailwindCss */
@tailwind base;
@tailwind components;

.PhoneInputInput {
  border: transparent;
  padding: 3px 0;
}

.PhoneInput {
  border-radius: 4px;
  padding: 5px;
  @apply border border-gray-600 h-12 rounded-md;
}

.PhoneInputInput {
  border: transparent;
  @apply bg-transparent dark:text-gray-300 outline-none focus:outline-none;
}

.PhoneInputInput:focus {
  @apply border-none outline-none;
}

.sub-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.sub-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

@tailwind utilities;
